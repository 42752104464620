/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import "~bootstrap/scss/bootstrap.scss";

body {
  background-color: transparent;
  // height: 100%;
}

.customer-navbar-header {
  margin-left: 150px;
  margin-right: 150px;
}

.customer-navbar-content {
  margin-left: auto;
  margin-right: auto;
  padding-top: 95px;
  padding-bottom: 95px;
}

.customer-navbar-image {
  position: relative;
  width: 100%;
}

@media (max-width: 1024px) {
  .customer-navbar-header {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.title-header {
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
}

.title-header-button button {
  font-size: 18px;
  // padding-left: 12px;
  // padding-right: 12px;
}

.title-header-button a {
  font-size: 16px;
  padding-left: 16px;
  padding-right: 12px;
}

.title-header-button div {
  width: 100%;
}

.title-header-link {
  font-size: 0.7rem;
  color: #c1d5dc;
  font-weight: 600;
}

.title-header-link:hover {
  color: #c1d5dc;
}

.review-header-column {
  width: 160px;
}

.review-name {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1400px) {
  .review-header-column {
    width: 150px;
  }
}

@media (max-width: 1200px) {
  .review-header-column {
    width: 100px;
  }
}

@media (max-width: 1024px) {
  .review-header-column {
    width: 110px;
  }
}

@media (max-width: 850px) {
  .review-header-column {
    width: 100px;
  }
}

@media (max-width: 476px) {
  .review-header-column {
    width: 150px;
  }
}

.inner-card-bottom {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.inner-card-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.review-video-clip > div {
  width: auto !important;
  height: auto !important;
}

.review-audio-container {
  width: 100%;
  background-color: #f1f3f4;
}

.review-audio-container-dark {
  width: 100%;
  // background-color: #4d4d4d;
  // color: #4d4d4d;
  background-color: #f1f3f4;
}

.xmasonry .xblock {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 65px;
}

.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.wordwrapper {
  text-align: center;
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
}

.word {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  background: white;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 20%;
  }

  .line {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

.profile-default-Image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #512da8;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.carousel-indicators-margin .carousel-indicators {
  margin-bottom: -2.5rem !important;
}

@media (max-width: 991.5px) {
  .carousel-mobile-card {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
}

// Set Dark Theme
// .dark-theme-body {
//   padding-top: 30px;
//   background-color: #000000;
// }

// .white-theme-body {
//   padding-top: 30px;
//   background-color: #fff;
// }

.main-review-page-padding {
  padding-top: 35px;
}

// .dark-theme-body{
//border: 1.5px solid white;
// border: 1px solid #6a6969;
//box-shadow: 0 0 20px rgb(0 0 0 / 30%);
// border-radius: 0.5rem;
// }

// .dark-theme-body .dark-theme-card > div {
//   border: none
// }

.dark-theme-body .dark-inner-card {
  background-color: #2b2b2b;
}

.dark-theme-body .set-text-white {
  color: white;
}

.dark-theme-body .set-text-light-white {
  color: white !important;
  font-weight: 200;
}

.dark-theme-body .modal-content {
  background-color: #2b2b2b;
  color: white;
}

.dark-theme-body .modal-content .modal-header button {
  background-color: white;
}
.modal-content{
  border: none;
  background-color: rgba(0, 0, 0, 0.0);
}

.dark-theme-body img {
  background-color: white;
}

.dark-theme-body .set-video-dark {
  background-color: #121212;
}

.dark-theme-body .set-input-dark {
  background-color: #2b2b2b;
  color: white;
}

.dark-theme-body .title-header-button div {
  background-color: #121212;
  border: 1px solid #707070;
}

.dark-theme-body .title-header-button div a {
  color: white;
}

.dark-theme-body .title-header-button div a:hover {
  background-color: #121212;
}

//Single Review Page
.anr-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

// .Sreview-title-header {
//   font-size: 30px;
//   font-weight: 500;
//   line-height: 30px;
// }

// .Sreview-title-header-link {
//   font-size: 14px;
//   color: white;
// }

@media (max-width: 1024px) {
  .SReview-brand-logo {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .record-btn-mobile {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .upload-btn-mobile {
    width: 100%;
    margin-top: 0.5rem;
  }
}

.preview-video-rotate {
  -webkit-appearance: none;
  // transform: rotateY(180deg) !important;
}

.preview-video-rotate::-webkit-media-controls {
  // transform: rotateY(180deg) !important;
}

.preview-image-rotate {
  // transform: rotateY(180deg);
}

// Main review page ------------------------------
/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */

  /* Set a specific height */
  height: 600px;
  // 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.card-review {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.card-review-dark {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #121212;
  background-clip: border-box;
  color: #c1d5dc;
  border-radius: 0.5rem;
  // border: 1px solid #d9d7d7;
  border: 1px solid rgba(255, 255, 255, 0.125);
  // box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.125);
}

// Brand not found CSS

.not-found-card {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.not-found-create-review-brandname {
  font-size: 16px;
  font-weight: 400;
}

.not-found-create-review-title-header {
  font-size: 36px;
  font-weight: 800;
  line-height: 40px;
}

// ================

video,
audio {
  // clip-path: inset(0px 0px);
  // border: none;
  // outline: unset;
  // line-height: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

#video {
  border-radius: 0.5rem;
  transform: scaleX(-1);
}

.create-review-card {
  // position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.create-review-brandname {
  font-size: 16px;
  font-weight: 400;
}

.create-review-title-header {
  font-size: 36px;
  font-weight: 800;
  line-height: 40px;
}

.create-review-brand-logo {
  height: 70px;
}

.border-radius-05 {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded {
  border-radius: 0.5rem;
}

// Profile photo

.border-profile {
  border: 1px solid #939590;
  border-radius: 50%;
}

.underline-on-hover:hover {
  text-decoration: underline;
  color: inherit;
}

.underline-on-hover {
  text-decoration: none;
  color: inherit;
}

//absolute video div
.sc-dkrFOg{
  position: absolute;
  text-align: center;
}


// takeVideo CSS
.SVGInline {
  display: none;
}

.render-actions__ActionsWrapper-dp6lnv-0 {
  position:absolute;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: auto !important;
}

.record-button__ButtonBorder-sc-1n5amwk-2{
  height: 45px !important;
  width: 45px !important;
  border: 6px solid rgba(255,255,255,0.4) !important;
}

.record-button__Button-sc-1n5amwk-0{
  width: 100% !important;
  height: 100% !important;
}

.record-button__Instructions-sc-1n5amwk-3 {
  margin-bottom: 5px !important;
  font-size: 10px !important;
}

.button__Button-hkteey-0{
  margin: 0px !important;
}

.stop-button__Border-sc-1h536gx-1 {
  height: 45px !important;
  width: 45px !important;
  padding: 10px !important;
}

.stop-button__Button-sc-1h536gx-0 {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px;
}

.video-recorder__Wrapper-sc-7k20rv-0 {
  border-radius: 0.5rem;
  padding-top: 56.25%;
  min-height: auto !important;
  @media (max-width: 700px) {
    padding-top: 133.3%;
  };
}

.disconnected-view__SVGWrapper-sc-1hdjm8z-0 {
  margin-bottom: 0px !important;
}

//disable flipcam
.switch-camera-view__SVGWrapper-sc-13l3hbu-0 {
  display: none;
}


html, body{
  height: 100vh;
}

#root {
  height: 100vh;
}

.mediaBorder {
  border-radius: 0.6rem;
  border-style: solid;
  border-width: 2px;
}

.modal-backdrop.show{
  opacity: 0.2;
}